import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/CulturalClub/TalenShow/1.jpg';
// import p2 from 'assests/Photos/Clubs/CulturalClub/TalenShow/2.jpg';
// import p3 from 'assests/Photos/Clubs/CulturalClub/TalenShow/3.jpg';
// import p4 from 'assests/Photos/Clubs/CulturalClub/TalenShow/4.jpg';
// import p5 from 'assests/Photos/Clubs/CulturalClub/TalenShow/5.jpg';
// import p6 from 'assests/Photos/Clubs/CulturalClub/TalenShow/6.jpg';
// import p7 from 'assests/Photos/Clubs/CulturalClub/TalenShow/7.jpg';
// import p8 from 'assests/Photos/Clubs/CulturalClub/TalenShow/8.jpg';
// import p9 from 'assests/Photos/Clubs/CulturalClub/TalenShow/9.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import CulturalHeritageClub from '../CulturalHeritageClub';


const TalentShow2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));  //
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/clubs/CulturalClub/TalenShow/9.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source:p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
          },
          {
              src: p9,
              source: p9,
              rows: 1.5,
              cols: 2,
            },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Talent Show
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classe: PRE-PRIMARY   &  Date: 25 & 26 JULY 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"From little seeds grow mighty trees. Let's show our talents with joy and ease!"

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Pre-Primary talent show, conducted on Thursday, 25 th July 2024 and Friday, 26 th July 2024, was a delightful display
of our students&#39; diverse talents in dancing, singing, mimicry and playing musical instruments. The event was conducted
in the school auditorium which created an elevated arena for the little stars to perform.
        <br></br>
        The Talent Show commenced with a warm welcome by Principal Ma’am, who emphasised on the importance of
nurturing creativity and self-expression in early childhood. With this motivation the Talent Show of the tiny tots began
with zeal. Children were elated to showcase their talents with vibrant energy.

Children presented their moves to lively tunes, ranging from classical dance to modern hip-hop. Their enthusiasm and
rhythmic movements captivated the audience. The budding vocalists took the stage, singing popular nursery rhymes,
traditional songs and a few contemporary hits. Their sweet voice and charming stage presence received much applause
from the audience. The highlight was young musicians playing instruments. Their synchronization and sense of rhythm
were impressive for their age. Principal Ma’am praised the children's efforts and encouraged them to continue
exploring their talents.

The children performed with remarkable confidence and enthusiasm. Their dedication to practicing and perfecting their
act was evident and their joy in performing was contagious. The talent show provided numerous learning opportunities
for the children. Performing on the stage helped the children build self-confidence and overcome stage fright. The
event encouraged the children to express themselves creatively through different mediums, fostering a love for
performing arts.

This talent show was a resounding success, leaving everyone with fond memories and a renewed appreciation for the
boundless potential of young minds. As Pablo Picasso reminds us, ‘Every child is an artist. Our little stars were here to
shine and show how their talents can light up the world!
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
You are a rainbow of possibilities
<br/>
The sky is the limit when you believe in

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <CulturalHeritageClub/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default TalentShow2024;